.container {
    margin: 20px;
  }
  
h2 {
    color: rgb(65, 65, 69);
    font-size: 24px;
    font-weight: bold;
}
p{
    font-size: 18px;
    padding: 10px;
    text-align: justify;
}